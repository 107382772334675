<template>
  <Carousel
   :settings="settings" 
   :slides="slides"  
   @init="handleInit"
   @slide-start="handleSlideStart"
   @slide-end="handleSlideEnd"
  >
    <Slide v-for="slide in slides" :key="slide">
      <div class="carousel__item slidergrid">
        <div><img :src="slide.img" class="slimg"></div>
       
        <div class="slidertxtwrapper">
          <h3 class="sliderHeadline" v-html="slide.headline"></h3>
          <p class="slidertxt xl:text-xl lg:text-lg" v-html="slide.txt">  </p></div>
      
      </div>
    </Slide>

    <template #addons>
      <Pagination />
      <Navigation />
     
    </template>
  </Carousel>
  
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import sliderdata from '../assets/data/slider.json'

import 'vue3-carousel/dist/carousel.css' 

export default defineComponent({
  name: 'ExamplePagination',
  components: {
    Pagination,
    Carousel,
    Slide,
    Navigation,
  },
  methods: {
      handleInit() {
        console.log('created')
      },
      handleSlideStart(data) {
        console.log('slide-start', data)
      },
      handleSlideEnd(data) {
        console.log('slide-End', data)
      },
    },
  data: () => ({
    // carousel settings
    slides:sliderdata.slides,
    publicPath: process.env.BASE_URL,
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',

      pauseAutoplayOnHover:true,
   autoplay:4000,
      transition:1000,
      wrapAround:true,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings

  }),
})
</script>

<style lang="less">
 @import "../assets/mediaq.less";
.carousel__prev, .carousel__next{
     color: #fff;    width:70px;
     height:70px;
}
.carousel__icon {
     width: 70px;
     height:70px;
     fill: currentColor;
 }
.slidergrid{
  display: grid; grid-template-columns: 1fr;
  
 
  gap: 30px;
  width: 100%;
  margin:0 2%;

  @media @md{  margin:0 10%;
    grid-template-columns: 1fr 1fr;
  }
}

.slidertxtwrapper{
  color:#fff; @media @md{text-align: right;}
}
.sliderHeadline{
 font-family: Bitter, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

 font-size: 1.875rem;
  @media @sm{  font-size: 1.875rem;  }
  @media @md{  font-size: 2.875rem;  }
  @media @lg{  font-size: 3rem;  }
  @media @xl{  font-size: 3.875rem;  }
  font-weight: 900;
  text-shadow: 3px 3px 6px #00000050;
 display: inline-block;
 padding-bottom: 10px;
 position: relative;
 margin-bottom: 40px;
 &:after{
  content: '';
  width: 50%;
  bottom: 0;
  right: 25%;
  @media @md{
  right: 0;}
  position: absolute;
  height: 8px;
  background-color: #FFEC11;
  box-shadow: 0 3px 6px 0 #00000026;
 }
}


.slimg{
  margin: auto;
  max-height: 300px;
  width: auto;
  @media @sm{  max-height: 300px;   }
  @media @md{  max-height: 300px;  }
  @media @lg{  max-height: 300px;   }
  @media @xl{  max-height: 400px;  }
}
.carousel__pagination-button{
  background-color: transparent;

  &:after{
    background-color: transparent;
    border: solid 1px #efefef;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  }
  &:hover{
    &:after{
      background-color: #fff;
    }
  }
}
.carousel__pagination-button:hover::after, .carousel__pagination-button--active::after{
  background-color: #fff;
}

</style>