export default function windowScrollPosition(propertyName) {
    return {
      data() {
        return {
          // Initialize scroll position at [0, 0]
          scrollfaktor:window.innerHeight,
          scrolltop:window.scrollY,
          lastScrollTop:0,
          scrollupcounter:0,
          [propertyName]: false
        }
      },
      created() {
        // Only execute this code on the client side, server sticks to [0, 0]
        if (!this.$isServer) {
          this._scrollListener = () => {
            this.scrolltop=Math.round(window.pageYOffset);
                if( this.scrolltop > this.scrollfaktor  ){
                    document.body.classList.add("scrollActive");
                }else{
                    document.body.classList.remove("scrollActive", "scrollActive2");
                }

                if (this.scrolltop > this.lastScrollTop){      
                    this.scrollupcounter=0;   
                    document.body.classList.remove("scrollActive2");
                }  else {
                    this.scrollupcounter =this.scrollupcounter-(this.lastScrollTop-this.scrolltop);
        
                    if(this.scrollupcounter< -10){
                        document.body.classList.add("scrollActive2");
                }
                      
                   }
                   this.lastScrollTop = this.scrolltop;
            this[propertyName] = [
              Math.round(window.pageXOffset),
              Math.round(window.pageYOffset)
            ]
          }
  
          // Call listener once to detect initial position
          this._scrollListener()
  
          // When scrolling, update the position
          window.addEventListener('scroll', this._scrollListener)
        }
      },
      beforeDestroy() {
        // Detach the listener when the component is gone
        window.removeEventListener('scroll', this._scrollListener)
      }
    }
  }
  