<template>

    <div id="topbar">
      <nav class=" px-2 sm:px-4 py-2.5  w-full z-20 top-0 left-0 ">
  <div class="container relative flex flex-wrap items-center justify-between mx-auto">   
  <a href="/" >
    <img alt="Kingdöner Logo" src="../assets/Logo.svg" class="w-48 md:w-60 lg:w-80" id="logo">

  </a>
  
  <div class="fill-remaining-space"></div>
  
  <div class="flex ">
 
        <button class=" group lg:hidden hamburger"  @click="togglemenu">
        <div class="relative flex overflow-hidden items-center justify-center rounded-full w-[50px] h-[50px] transform transition-all bg-slate-700 ring-0 ring-gray-300 hover:ring-8 ring-opacity-30 duration-200 shadow-md" :class="{'ring-4': togglestate}">
          <div class="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
            <div class="bg-white h-[2px] w-7 transform transition-all duration-300 origin-left" :class="{'translate-x-10': togglestate}"></div>
            <div class="bg-white h-[2px] w-7 rounded transform transition-all duration-300 delay-75" :class="{'translate-x-10': togglestate}"></div>
            <div class="bg-white h-[2px] w-7 transform transition-all duration-300 origin-left delay-150" :class="{'translate-x-10': togglestate}"></div>

            <div class="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10  flex w-0 " :class="{'translate-x-0 w-12': togglestate}">
              <div class="absolute bg-white h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300 " :class="{'rotate-45': togglestate}"></div>
              <div class="absolute bg-white h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300 " :class="{'-rotate-45': togglestate}"></div>
            </div>
          </div>
        </div>
      </button>
  </div> 
  <div class="items-center justify-between hidden w-full lg:flex md:w-auto " id="navbar"  >

    <ul class="flex flex-col p-4 mt-4  md:flex-row  md:mt-0 "  id="mainmenu"  v-scroll-spy-active v-scroll-spy-link>
      <li>
        <router-link to="/"  @click="resetmenu">Home</router-link>
      </li> 
      <li>
        <router-link to="/#menu"  @click="resetmenu">Menü</router-link>
      </li>      
      <li>
       <router-link to="/#cafe"  @click="resetmenu">Café</router-link>
            </li>    
      <li>
        <router-link to="/#history"  @click="resetmenu">Über uns</router-link>
      </li>
      <li>
        <a href="https://www.google.com/maps/dir//Das+Barnimer+Grillhaus+King+D%C3%B6ner,+Sch%C3%B6nower+Str.+40,+16341+Panketal/@52.6725624,13.5306028,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47a9b336521753e5:0x4541028d0778cb8f!2m2!1d13.5324346!2d52.6592644!3e0" target="_blank"  @click="resetmenu"> <svg  class="inlinesvg" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z"/></svg> Anfahrt</a>

      </li>
    </ul> 
  </div>


  </div>
</nav>
   

<div id="mobilenav" :class="{'mobiletoggle': togglestate}">
    <ul class=" "  id="mainmenu2" >
      <li>
        <router-link to="/"  @click="resetmenu">Home</router-link>
      </li> 
      <li>
        <router-link to="/#menu"  @click="resetmenu">Menü</router-link>
      </li>      
      <li>
       <router-link to="/#cafe"  @click="resetmenu">Café</router-link>
            </li>    
      <li>
        <router-link to="/#history"  @click="resetmenu">Über uns</router-link>
      </li>
      <li>
        <a href="https://www.google.com/maps/dir//Das+Barnimer+Grillhaus+King+D%C3%B6ner,+Sch%C3%B6nower+Str.+40,+16341+Panketal/@52.6725624,13.5306028,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47a9b336521753e5:0x4541028d0778cb8f!2m2!1d13.5324346!2d52.6592644!3e0" target="_blank"  @click="resetmenu">Anfahrt</a>
      </li>
    </ul> 
  </div>

    </div>



</template>

<script>
import windowScrollPosition from '@/mixins/window-scroll-position'


export default {
  name: 'MainNavigation',
  data(){
    return{
      vh: window.innerHeight,
      togglestate:false
    }
  },
  mixins: [windowScrollPosition('position')],
  created() {
  window.addEventListener("resize", this.CheckMenu);
},
unmounted() {
  window.removeEventListener("resize", this.CheckMenu);
},
  methods:{
    togglemenu(){
      this.togglestate = !this.togglestate;
    },
    resetmenu(){
      this.togglestate=false
    },
    CheckMenu(){
      var w = window.innerWidth;
            if (w > 1024) {
              this.togglestate=false
            }
    }
  },
  mounted() {

  },
  components: {
   
  }
}
</script>

<style lang="less">
 @import "../assets/mediaq.less";
#topbar{
  position: relative;
width: 100%;
z-index: 100;
.hamburger{z-index:100; .ring-gray-300{background-color: var(--yellow); .bg-white{background-color: var(--green);}}}
#navbar{
  display: block;
  position: absolute;
  top:0; right: 0;
  transform: translateX(1000px);
  -webkit-transition: transform 0.8s ease-out;
-moz-transition: transform 0.8s ease-out;
-ms-transition: transform 0.8s ease-out;
-o-transition: transform 0.8s ease-out;
transition: transform 0.8s ease-out;
  @media @lg{
    transform: none;
    right:auto;
    position: relative;
  }
  
}

#mobilenav{
  position: absolute;
  top:90px; right:0;
  z-index: 99;
  transform: translateX(500px);
  -webkit-transition: transform 0.3s ease-out;
-moz-transition: transform 0.3s ease-out;
-ms-transition: transform 0.3s ease-out;
-o-transition: transform 0.3s ease-out;
transition: transform 0.3s ease-out;
padding-top: 50px;
-webkit-box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.5);
box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.5);
background-color: var(--bggreen);
border:solid 5px var(--yellow);
border-right: none;
padding: 30px;
-webkit-border-radius: 10px 0 0 10px;
border-radius: 10px 0 0 10px;
a{ font-size: 1.313rem;
   color:#fff;
   font-weight: 600;
   text-transform: uppercase;
   .inlinesvg{
    display: inline;
    color:#fff;
    path{fill:#fff}
  }
  }
   li{
    &.active,&:hover{
      a{
      color: var(--yellow);
      &:before{
        top: -20px;
      }
       }
    }
    .realpage{
      &.router-link-active{
        color: var(--yellow);
      }
    }
   }
  &.mobiletoggle{

    transform: translateX(0);


    z-index: 99;
    width: auto;
   
  }
}
#mainmenu{
   font-size: 1.313rem;
   color:#fff;
   font-weight: 600;
   text-transform: uppercase;
  li{
    &::before{
      content: '\00B7';
      display: inline-block;
      padding-right: 5px;padding-left: 5px;
      @media @md{padding-right: 10px; padding-left: 10px;}
      @media @lg{ padding-right: 10px; padding-left: 10px;}
      @media @xl{ padding-right: 20px; padding-left: 20px;}
  
   
    }
    &:last-child:after{
      content: '\00B7';
      display: inline-block;
      padding-left: 5px;
      @media @md{   padding-left: 10px;}
      @media @lg{ padding-left: 10px;}
      @media @xl{ padding-left: 20px;}
    }
    a{
      position: relative;
      .inlinesvg{
    display: inline;
    color:#fff;
    margin-top: -5px;
    path{fill:#fff}
  }
      &:before{
        content: '';
        background-image: url('~@/assets/icons/krone.svg');
        content: '';
    width: 30px;
    -webkit-transition: all 0.2s ease-out;
-moz-transition: all 0.2s ease-out;
-ms-transition: all 0.2s ease-out;
-o-transition: all 0.2s ease-out;
transition: all 0.2s ease-out;
    height: 20px;
    position: absolute;
    top: -200px;
    left: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateX(-50%);
      }
    }
    &.active,&:hover{
      a{
      color: var(--yellow);
      &:before{
        top: -20px;
      }
       }
    }
    .realpage{
      &.router-link-active{
        color: var(--yellow);
      }
    }
   }
}
}
.scrollActive {

  #logo{ width: auto; height: 90px}
}

.fill-remaining-space {
  flex: auto;
}


.scrollActive #topbar {
    position: fixed;
    z-index: 9998;
    top: -390px;
    left: 0;
    width: 100%;
    display: block;
    margin: 0;
    background-color:#009811;
    &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120%;
    height: 90%;
    opacity: 0.3;
    background: -moz-radial-gradient(center, ellipse cover, rgba(94, 255, 46, 1) 0%, rgba(0, 152, 17, 0.25) 74%, rgba(0, 152, 17, 0) 99%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(94, 255, 46, 1) 0%, rgba(0, 152, 17, 0.25) 74%, rgba(0, 152, 17, 0) 99%);
    background: radial-gradient(ellipse at center, rgba(94, 255, 46, 1) 0%, rgba(0, 152, 17, 0.25) 74%, rgba(0, 152, 17, 0) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5eff2e', endColorstr='#00009811', GradientType=1);

  }
    height: 120px;
  }


  .scrollActive.scrollActive2 #topbar {
    -webkit-transition: top 500ms ease;
    -moz-transition: top 500ms ease;
    -o-transition: top 500ms ease;
    transition: top 500ms ease;
    z-index: 9998;
    /* enable hardware acceleration to fix laggy transitions */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    top: 0;
    -moz-box-shadow: 0 0 43px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 0 43px rgba(0, 0, 0, .2);
    box-shadow: 0 0 43px rgba(0, 0, 0, .2);
  }
</style>