<template>
 

    <section class="px-2 py-8 md:px-5 md:py-12 greensection" id="cafe" >

      <div class=" container mx-auto">
        <h2 class="headline1">Unser Café</h2>

    <MenuGroupC 
    headline="Cafe,Desserts und Türkische Bäckerei" 
    fimg="Caffee.webp"  
    subheadline="Hier eine kleine Auswahl unserer Kaffee und Backwaren" 
    :items="[
      {
      'headline':'Kaffee', 
      'text':'Verschiedene Kaffee spezialitäten'
      },
      {
      'headline':'Tee', 
      'text':'16 verschiedene Bio Tees'
      }
      ,
      {
      'headline':'Desserts', 
      'text':'Kuchen und Torten'
      },
      {
      'headline':'Türkische Bäckerei', 
      'text':'Baklava, Simit(Sesamringe), Acma(Butterring mit Nutella Füllung, uvm...'
      }
      ]"
    />

 
    
</div>

    </section>

  </template>
  
  <script>
  // @ is an alias to /src
  import MenuGroupC from './MenuGroupC.vue'
  export default {
    name: 'CafePage',
    components: {
      MenuGroupC
    
    },
  }
  </script>