import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import '@/assets/main.css'
import '@/assets/mediaq.less';
import router from './router'
import { registerScrollSpy } from 'vue3-scroll-spy';
const KDApp=createApp(App);

registerScrollSpy(KDApp);
KDApp.use(router).mount('#app')

