<template>
  <section class="menu px-2 py-8 md:px-5 md:py-12" id="menu" style="min-height:100vh">
    <div class=" container mx-auto">
      <h1 class="headline1">Unser Menü</h1>
      <div class="grid lg:grid-cols-2 gap-8 text-left">

       

          <MenuGroup 
    headline="Unser Döner" 
    fimg="Kebap.webp"
    subheadline="Zur Auswahl stehen Hähnchen als auch Kalbsfleisch" 
    :items="[
      {
      'headline':'Döner', 
      'text':'Döner im Brot , Dürüm,Türkische Pizza oder im Teller mit Reis Nudeln oder Pommes'
      }]"
      />
         
         
       
          <MenuGroup 
    headline="Schnitzel Wurst & Co" 
    fimg="wurst.webp" 
    :items="[
      {
      'headline':'Schnitzel', 
      'text':'im Brot oder im Teller mit leckeren Beilagen'
      },
      {
      'headline':'Würste', 
      'text':'XXL Thüringer, Bratwürste, Krakauer, Wiener, Bockwürste u.v.m'
      }]"
      /> 
          
       

          <MenuGroup 
    headline="Burger, Pommes & Fingerfood" 
    fimg="Burger.webp" 
    :items="[
      {
      'headline':'King Burger (Double Cheesburger)', 
      'text':'Bun,zwei saftige Fleischpatties Burgersauce knackigem Salat Röstzwiebeln karamelisierte Zwiebeln und Bacon. Wir haben viele weitere Burger, darunter auch vegane Varienten im Angebot.'
      },
      {
      'headline':'Pommes', 
      'text':'BIO Pommes hausgemacht,  Bio Süßkartoffel Pommes hausgemacht, Twister Pommes, uvm...'
      },
      {
      'headline':'Fingerfood', 
      'text':'Chicken Nuggets, Mozzarella Sticks, Chicken Wings, BBQ Chicken Wings, uvm...'
      }
      ]"
    />
          
         
       
          <MenuGroup 
    headline="Veggies" 
    fimg="Salat.webp" 
    :items="[
      {
      'headline':'Gemischter Salat', 
      'text':'Der Klassiker und ganz individuell zusammengestellt mit knackigem Salat und vielen Dressings'
      },
      {
      'headline':'Suppen', 
      'text':'Eine Vielzahl an hausgemachten Suppen'
      },
      {
      'headline':'Falafel & Halumi', 
      'text':'Falafel & Halumi im Brot'
      }
      ]"
    />
        

        
          <MenuGroup 
    headline="Pizza  & Pide"  
    fimg="Pizza.webp"  
    subheadline="über 15 verschiedene Pizzen Stehen ihnen zur Auswahl" 
    :items="[
      {
      'headline':'Die Klassiker', 
      'text':'Pizza Salami , Pizza Mista, Pizza Milano und über 12 weitere Pizzen'
      },
      {
      'headline':'Pide', 
      'text':'Hausgemachte Pide mit Sucuk, Käse & Spinat'
      }
      ]"
    />
        
    <MenuGroup 
    headline="Getränke" 
    fimg="getraenke.webp"  
    subheadline="über 100 Verschiedene Getränke stehen Ihnen zur Auswahl." 
    :items="[
      {
      'headline':'Alkoholfrei', 
      'text':'Softdrinks, Säfte, Limonaden, uvm.'
      },
      {
      'headline':'Mit Alkohol', 
      'text':'Biere aus aller Welt, Spirituosen und Sekt'
      }
      ]"
    />
        
   
      </div>

    </div>


  </section>


</template>
  
<script>
// @ is an alias to /src

import MenuGroup from './MenuGroup.vue'
export default {
  name: 'MenuPage',
  components: {
  
    MenuGroup

  },
}
</script>