<template>
  <div id="footer">
    <div class="container mx-auto py-10">

      <div class=" px-3 sm:px-5 lg:px-10 lg:px-0 lg:flex lg:justify-between flex-row z-auto " id="footercontent">
        <div class="text-center ">
          <a href="/">
            <img alt="Kingdöner Logo" src="../assets/Logo.svg" class="w-50 md:w-60 lg:w-80">

          </a>
          <div class="flex justify-between px-5 pt-3">
            <a href="https://www.facebook.com/barnimergrillhaus/" target="_blank" class="socialbtn flex items-center text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="13.16" height="24.571" viewBox="0 0 13.16 24.571"
                class="mx-auto">
                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                  d="M13.907,13.821l.682-4.447H10.323V6.489a2.223,2.223,0,0,1,2.507-2.4h1.94V.3A23.656,23.656,0,0,0,11.326,0C7.812,0,5.515,2.13,5.515,5.985V9.375H1.609v4.447H5.515v10.75h4.807V13.821Z"
                  transform="translate(-1.609)" fill="#ffdf11" />
              </svg>
            </a>
            <a href="https://www.instagram.com/barnimergrillhaus/" target="_blank" class="socialbtn flex items-center text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="24.577" height="24.572" viewBox="0 0 24.577 24.572"
                class="mx-auto">
                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
                  d="M12.286,8.224a6.3,6.3,0,1,0,6.3,6.3A6.29,6.29,0,0,0,12.286,8.224Zm0,10.4a4.1,4.1,0,1,1,4.1-4.1,4.1,4.1,0,0,1-4.1,4.1ZM20.313,7.966A1.469,1.469,0,1,1,18.844,6.5,1.466,1.466,0,0,1,20.313,7.966Zm4.172,1.491A7.272,7.272,0,0,0,22.5,4.309a7.32,7.32,0,0,0-5.148-1.985c-2.029-.115-8.109-.115-10.138,0A7.309,7.309,0,0,0,2.066,4.3,7.3,7.3,0,0,0,.081,9.452c-.115,2.029-.115,8.109,0,10.138a7.272,7.272,0,0,0,1.985,5.148,7.329,7.329,0,0,0,5.148,1.985c2.029.115,8.109.115,10.138,0A7.272,7.272,0,0,0,22.5,24.738a7.32,7.32,0,0,0,1.985-5.148c.115-2.029.115-8.1,0-10.132ZM21.865,21.766A4.147,4.147,0,0,1,19.529,24.1c-1.617.642-5.456.493-7.243.493s-5.631.143-7.243-.493a4.147,4.147,0,0,1-2.336-2.336c-.642-1.617-.493-5.456-.493-7.243s-.143-5.631.493-7.243A4.147,4.147,0,0,1,5.043,4.945c1.617-.642,5.456-.493,7.243-.493s5.631-.143,7.243.493a4.147,4.147,0,0,1,2.336,2.336c.642,1.617.493,5.456.493,7.243S22.506,20.154,21.865,21.766Z"
                  transform="translate(0.005 -2.238)" fill="#ffdf11" />
              </svg>

            </a> <a href="https://www.google.com/maps/place/King+D%C3%B6ner+-+Das+Barnimer+Grillhaus+%26+Caf%C3%A9/@52.6592644,13.5324346,15z/data=!4m6!3m5!1s0x47a9b336521753e5:0x4541028d0778cb8f!8m2!3d52.6592644!4d13.5324346!16s%2Fg%2F11rscvyj9z?hl=de" target="_blank" class="socialbtn flex items-center text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="25.308" height="25.723" viewBox="0 0 25.308 25.723"
                class="mx-auto">
                <path id="Icon_awesome-google" data-name="Icon awesome-google"
                  d="M25.308,13.725c0,7.338-5.025,12.561-12.447,12.561a12.862,12.862,0,1,1,0-25.723,12.369,12.369,0,0,1,8.625,3.366l-3.5,3.366c-4.579-4.419-13.095-1.1-13.095,6.13a8.056,8.056,0,0,0,7.971,8.121c5.093,0,7-3.651,7.3-5.544h-7.3V11.578H25.106A11.275,11.275,0,0,1,25.308,13.725Z"
                  transform="translate(0 -0.563)" fill="#ffdf11" />
              </svg>

            </a>

          </div>
        </div>


        <div class="mt-12 lg:w-1/2 lg:mt-0">
          <h3 class="text-xl pb-4 font-bold text-left" style="color:var(--yellow)">Unsere Filiale in Zepernick / Panketal</h3>
          <div class="grid md:grid-cols-2">
            <div>
              <div class="flex flex-row  gap-x-3">
            <div class="basis-12">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="41.907"
                height="41.906" viewBox="0 0 41.907 41.906">
                <defs>
                  <filter id="Icon_weather-time-4" x="0" y="0" width="41.907" height="41.906"
                    filterUnits="userSpaceOnUse">
                    <feOffset dx="3" dy="3" input="SourceAlpha" />
                    <feGaussianBlur result="blur" />
                    <feFlood flood-opacity="0.349" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g id="clock" transform="translate(-1047.403 -3307.403)">
                  <g transform="matrix(1, 0, 0, 1, 1047.4, 3307.4)" filter="url(#Icon_weather-time-4)">
                    <path id="Icon_weather-time-4-2" data-name="Icon weather-time-4"
                      d="M4.488,23.316a19.075,19.075,0,0,1,2.626-9.778,19.437,19.437,0,0,1,7.1-7.1A19.416,19.416,0,0,1,31.508,5.366a19.628,19.628,0,0,1,6.2,4.146,19.048,19.048,0,0,1,4.146,6.22A19.319,19.319,0,0,1,31.508,41.2a19.389,19.389,0,0,1-15.134,0,19.707,19.707,0,0,1-6.22-4.146,19.549,19.549,0,0,1-4.146-6.185A18.723,18.723,0,0,1,4.488,23.316Zm4.285,0a14.683,14.683,0,0,0,4.475,10.677,14.592,14.592,0,0,0,10.711,4.475,14.688,14.688,0,0,0,7.584-2.039A15.327,15.327,0,0,0,37.089,30.9a15.049,15.049,0,0,0,0-15.186,15.242,15.242,0,0,0-5.546-5.546,15.128,15.128,0,0,0-15.169,0,15.557,15.557,0,0,0-5.563,5.546A14.629,14.629,0,0,0,8.773,23.316Zm13.683,0V11.81a1.434,1.434,0,1,1,2.868,0V22.435l6.2,3.611a1.41,1.41,0,0,1,.656.881A1.3,1.3,0,0,1,32.027,28a1.345,1.345,0,0,1-1.244.726,1.2,1.2,0,0,1-.726-.207l-6.6-3.853a1.422,1.422,0,0,1-.726-.5A1.313,1.313,0,0,1,22.455,23.316Z"
                      transform="translate(-4.49 -3.83)" fill="#ffdf11" />
                  </g>
                </g>
              </svg>
            </div>
            <div class=" text-left grow">
              <table class="table-auto  w-full mb-5">

                <tbody>
                  <tr>
                    <td class="">Mo-Fr:</td>
                    <td class="">07:00 - 22:00 Uhr</td>
                  </tr>
                 
                  <tr>
                    <td class="">Samstag:</td>
                    <td class="">08:30 - 22:00 Uhr</td>
                  </tr>
                  <tr>
                    <td class="">Sonntag:</td>
                    <td class="">11:00 - 22:00 Uhr</td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
            </div>
            <div>
              <div class="flex flex-row  gap-x-3  mb-5">
            <div class="basis-12">
   
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32.299" height="42.065" viewBox="0 0 32.299 42.065">
  <defs>
    <filter id="Icon_awesome-map-marker-alt" x="0" y="0" width="32.299" height="42.065" filterUnits="userSpaceOnUse">
      <feOffset dx="3" dy="3" input="SourceAlpha"/>
      <feGaussianBlur result="blur"/>
      <feFlood flood-opacity="0.271"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="pin" transform="translate(-1033.708 -3034.702)">
    <g transform="matrix(1, 0, 0, 1, 1033.71, 3034.7)" filter="url(#Icon_awesome-map-marker-alt)">
      <path id="Icon_awesome-map-marker-alt-2" data-name="Icon awesome-map-marker-alt" d="M13.144,38.277C2.058,22.206,0,20.556,0,14.65a14.65,14.65,0,0,1,29.3,0c0,5.907-2.058,7.556-13.144,23.628a1.832,1.832,0,0,1-3.011,0ZM14.65,20.753a6.1,6.1,0,1,0-6.1-6.1A6.1,6.1,0,0,0,14.65,20.753Z" fill="#ffdf11"/>
    </g>
  </g>
</svg>

            </div>
            <div class=" text-left ">
              Schönower Str. 40<br>16341 Panketal


            </div>
          </div>

          <div class="flex flex-row  gap-x-3 mb-5">
            <div class="basis-12">
   
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31.414" height="31.414" viewBox="0 0 31.414 31.414">
  <defs>
    <filter id="Icon_awesome-phone-alt" x="0" y="0" width="31.414" height="31.414" filterUnits="userSpaceOnUse">
      <feOffset dx="3" dy="3" input="SourceAlpha"/>
      <feGaussianBlur result="blur"/>
      <feFlood flood-opacity="0.29"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="phone" transform="translate(-1032.491 -3104.008)">
    <g transform="matrix(1, 0, 0, 1, 1032.49, 3104.01)" filter="url(#Icon_awesome-phone-alt)">
      <path id="Icon_awesome-phone-alt-2" data-name="Icon awesome-phone-alt" d="M27.6,20.078l-6.216-2.664a1.332,1.332,0,0,0-1.554.383l-2.753,3.363a20.57,20.57,0,0,1-9.833-9.833l3.363-2.753a1.329,1.329,0,0,0,.383-1.554L8.33.805A1.341,1.341,0,0,0,6.8.034L1.032,1.366A1.332,1.332,0,0,0,0,2.664a25.747,25.747,0,0,0,25.75,25.75,1.332,1.332,0,0,0,1.3-1.032l1.332-5.772a1.348,1.348,0,0,0-.778-1.532Z" transform="translate(0 0)" fill="#ffdf11"/>
    </g>
  </g>
</svg>


            </div>
            <div class=" text-left ">
              <a href="tel:004917665993622">0176 65 993 622</a>


            </div>
          </div>

            </div>
          </div>
    

  
          <div class="flex flex-row  gap-x-3 mb-5">
            <div class="basis-12">
   
              


            </div>
            <div class=" text-left ">
              <router-link to="/impressum" class="z-auto footernav">Impressum & Datenschutz</router-link> 
              <a href="https://www.google.com/maps/dir//Das+Barnimer+Grillhaus+King+D%C3%B6ner,+Sch%C3%B6nower+Str.+40,+16341+Panketal/@52.6725624,13.5306028,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47a9b336521753e5:0x4541028d0778cb8f!2m2!1d13.5324346!2d52.6592644!3e0" target="_blank"  @click="resetmenu" class="footernav">
                <svg  class="inlinesvg" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z"/></svg> Anfahrt</a>


<a href="https://www.mosaic.net/" title="Webdesign Agentur Mosaic aus Berlin" target="_blank" class="mosaicfooterlink">Webdesign Agentur Berlin Mosaic</a>
<div id="copyright">
  Konzept und Screendesign von ARTX Berlin, den Spezialisten für 

<a href="https://www.artx.eu/wechselbilder.htm" title="Wechselbilder selber machen ">Wechselbilder selber machen</a> 

und um sich ein persönliches <a href="https://www.artx.eu/familienwappen.htm" title="Familienwappen erstellen lassen">Familienwappen erstellen lassen</a> durch Berliner Grafiker.
</div>
            </div>
          </div>



         
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  props: {
    msg: String
  }
}
</script>
<style lang="less">
#copyright{height:0; overflow: hidden;visibility: hidden;}
#footer {
  position: relative;
  background-color: var(--green);
  color: #fff;
  min-height: 15rem;
  overflow: hidden;
  .mosaicfooterlink {
    display: block;
    color: var(--yellow);
    
    font-size: 12px;
    text-decoration: none;
    padding-bottom: 15px;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    &:hover{
      color:var(--green);
    }
}
 img{max-width: 320px; display: inline-block; margin: auto;}
  #footercontent {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 120%;
    background: -moz-radial-gradient(center, ellipse cover, rgba(94, 255, 46, 1) 0%, rgba(0, 152, 17, 0.25) 74%, rgba(0, 152, 17, 0) 99%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(94, 255, 46, 1) 0%, rgba(0, 152, 17, 0.25) 74%, rgba(0, 152, 17, 0) 99%);
    background: radial-gradient(ellipse at center, rgba(94, 255, 46, 1) 0%, rgba(0, 152, 17, 0.25) 74%, rgba(0, 152, 17, 0) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5eff2e', endColorstr='#00009811', GradientType=1);

  }
}

.socialbtn {
  border: solid 1px var(--yellow);
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
 & svg{
  display: inline-block;
  
 }
  &:hover {
    background-color: var(--yellow);

    path {
      fill: var(--green);
    }
  }
}


.footernav {
  border: solid 1px var(--yellow);
  display: inline-block;
  padding: 5px 10px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin-bottom: 15px;
  margin-right: 15px;
  .inlinesvg{
    display: inline;
    color:#fff;
    path{fill:#fff}
  }
  &:hover {
    background-color: var(--yellow);
    color: var(--green);
    .inlinesvg{
   
    path{fill:var(--green);}
  }
  }
}
</style>
