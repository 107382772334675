<template>
    <div class="flex pb-5">
        <div class="flex-none w-14 mr-3">
       <div class="imgcircle" :style="inlineStyle"></div>
      </div>
      <div >
       <h3><b>{{headline}}</b></h3>
        <p>{{text}}</p>
      </div>
    </div>
    </template>
    
    <style>

    .imgcircle{width: 90%;
    padding-bottom: 80%; border:solid 2px var(--green); border-radius: 100%;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  }
    </style>
    
    <script>
    export default {
      name: 'MenuItem',
      props: {
        fimg: String,
     
        headline: String,
        text: String,
  },
  computed: {
  bgImage () {
    return require('@/assets/icons/krone.svg');
   
  },
  inlineStyle () {
    return {
      backgroundImage: `url(${this.bgImage})` 
    }
  }
}
  
 
    }
    </script>