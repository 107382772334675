<template>

<div class="grid lg:grid-cols-3 gap-8 foodgroup2 rounded-lg " >
  <div  class="lg:col-span-2 text-left">
    <div class="groupheader " :style="inlineStyle">
        <div class="groupname"><h3 class="headline2 ">{{headline}}</h3></div>
    </div>
  </div>
  <div  class="lg:col-span-1  text-left">
    <div class="groupbody ">
        <p v-if="subheadline"><b>{{ subheadline }}</b></p>
       <ul>
        <li v-for="item in items"  :key="item.headline">
            <MenuItem :headline="item.headline"  :text="item.text"  />
</li>
       </ul>
     
    </div>
  </div>
</div>

 
    </template>
    
    <style lang="less">
     @import "../assets/mediaq.less";
.foodgroup2{
    background-color: #fff;
    overflow:hidden;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    .groupheader{width: 100%;
    padding-bottom: 100%; 
    @media @md{
      padding-bottom: 50%; 
    }
    
    height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative; 
  overflow:hidden;
  display: block;


  .groupname{
    position: absolute;
    bottom:10px;
    width: 100%;
    background-color: #449128aa;
    color: var(--yellow);
    padding: 5px;
    text-align: center;
  }
  }
  .groupbody{
    padding:15px;
    padding-top: 30px;
  }
}
    </style>
    
    <script>

import MenuItem from './MenuItem.vue'
    export default {
      name: 'MenuGroupC',
      components: {
    MenuItem

  },
      props: {
        fimg: String,
        subheadline:String,
        headline: String,
        items: Object,
  },
  computed: {
   
    bgImage () {
    try {
  let fileName = require('@/assets/menutop/'+this.fimg);
  return fileName;

} catch (e) {
 return require('@/assets/icons/krone.svg');
}
   
  },
  inlineStyle () {
    return {
      backgroundImage: `url(${this.bgImage})` 
    }
  }
}
  
 
    }
    </script>