<template>

  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
   
      <component :is="Component" />
    </transition>
  </router-view>




</template>


<script>
// @ is an alias to /src


export default {
 
  components: {
   
   
  },
}
</script>
<style lang="less">
body,html{
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
#app {
  font-family: Bitter, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
 

}

.greensection{
  background-color:#009811;
  .headline1{color:#fff}
}
.headline1{
 font-family: Bitter, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 3rem;
  font-weight: 900;
color:var(--green);
text-shadow: 3px 3px 2px #00000017;
 display: inline-block;
 padding-bottom: 10px;
 margin-top: 30px;
 position: relative;
 text-align: center;
 margin-bottom: 40px;
 &:after{
  content: '';
  width: 50%;
  bottom: 0;
  left: 25%;
  position: absolute;
  height: 8px;
  background-color: #FFEC11;
  box-shadow: 0 3px 6px 0 #00000026;
 }
 &:before{
        content: '';
        background-image: url('~@/assets/icons/krone.svg');
        content: '';
    width: 60px;
    -webkit-transition: all 0.2s ease-out;
-moz-transition: all 0.2s ease-out;
-ms-transition: all 0.2s ease-out;
-o-transition: all 0.2s ease-out;
transition: all 0.2s ease-out;
    height: 40px;
    position: absolute;
    top: -30px;
    left: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateX(-50%);
      }
}

.headline2{
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

p{
  padding-bottom: 1rem;
}
</style>
