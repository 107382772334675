<template>
<header class="">
  
  <MainNavigation/>
<div class="headerwrapper container mx-auto">

    <div class="sliderwrapper">
    <VueSlider /> 
</div>
    <div class="lights1"></div><div class="lights2"></div>
</div>
</header>
</template>
<script>

import VueSlider from './VueSlider.vue'
import MainNavigation from './MainNavigation.vue'

export default {
  name: 'PageHeader',
  components: {
    VueSlider,
    MainNavigation
  },
 
}
</script>
<style lang="less">
 @import "../assets/mediaq.less";
header{
    background-color:#009811;
    min-height: 100vh;
    padding-bottom: 90px;
    position: relative;
    overflow: hidden;
    z-index: 3;
   
}
.headerwrapper{
 
   .sliderwrapper{ z-index: 1;
    position: relative;
    @media @lg{
      padding: 90px 0;
    }

    display: block;
    }
   .lights1{
   z-index: 0;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    opacity: 0.5;
    background: -moz-radial-gradient(center, ellipse cover,  rgba(94,255,46,1) 0%, rgba(0,152,17,0.25) 74%, rgba(0,152,17,0) 99%);
background: -webkit-radial-gradient(center, ellipse cover,  rgba(94,255,46,1) 0%,rgba(0,152,17,0.25) 74%,rgba(0,152,17,0) 99%);
background: radial-gradient(ellipse at center,  rgba(94,255,46,1) 0%,rgba(0,152,17,0.25) 74%,rgba(0,152,17,0) 99%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5eff2e', endColorstr='#00009811',GradientType=1 );

   }
   .lights2{
   z-index: 0;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height:10%;
    opacity: 0.5;
    background: -moz-radial-gradient(center, ellipse cover,  rgba(94,255,46,1) 0%, rgba(0,152,17,0.25) 74%, rgba(0,152,17,0) 99%);
background: -webkit-radial-gradient(center, ellipse cover,  rgba(94,255,46,1) 0%,rgba(0,152,17,0.25) 74%,rgba(0,152,17,0) 99%);
background: radial-gradient(ellipse at center,  rgba(94,255,46,1) 0%,rgba(0,152,17,0.25) 74%,rgba(0,152,17,0) 99%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5eff2e', endColorstr='#00009811',GradientType=1 );

   }
}
</style>