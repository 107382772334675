<template>
  <div class="home " >
    <div  v-scroll-spy>
    <PageHeader  />
 
    <Menupage  />
    <CafePage  />
    <HistoryPage  />
    
  </div> 
  <PageFooter  />
  </div>
</template> 

<script>
// @ is an alias to /src


import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import Menupage from '@/components/MenuPage.vue'
import HistoryPage from '@/components/HistoryPage.vue'
import CafePage from '@/components/CafePage.vue'

export default {
  name: 'HomePage',
  components: {
    PageHeader,
    Menupage,
    HistoryPage,
    CafePage,
    PageFooter
  
  },
}
</script>