<template>
  <div class=" container mx-auto">
    <section class="history px-5 py-8 md:px-5 md:py-12" id="history"  >
     
      <h2 class="headline1">Über uns</h2>
      <div class="md:grid md:grid-cols-2 md:gap-4 text-left">
<div>
  <p class="font-bold">
      Wir, die Familie Yildirim (King Döner das Barnimer Grillhaus), 
      sind als lokales Barnimer Familienunternehmen bereits in der fünften Generation in der Gastwirtschaft tätig (seit 1893). 
      </p>
      <p>
      Im Jahr 2013 haben wir unser fünftes Generationsprojekt mit dem Barnimer Grillhaus gestartet. 
      Wir sind eine kleine Zepernicker Familie und heimatverbunden. 
      Daher sind für uns soziale Verantwortung, Solidarität und genossenschaftliches Denken und Handeln seit jeher wichtige Eckpfeiler unserer Unternehmens- und Familienphilosophie. 
    </p>
    <blockquote class="text-xl italic font-semibold text-gray-900 dark:text-white py-8 flex" style="color:var(--green)">
    <svg aria-hidden="true" class="w-20 h-20 text-gray-400 dark:text-gray-600 pr-4" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
Gelebte Unternehmehmerische Verantwortung ist gut für die Geselschaft</blockquote>
      <p>
Mit einer Summe von bis zu 5.000 Euro im Jahr leisten wir gemeinsam mit Ihnen unseren Beitrag, um die Lebensqualität in unserem schönen Panketal und darüber hinaus im Landkreis Barnim für Groß und Klein zu erhalten und zu fördern. Mit jedem Einkauf unterstützen und leisten auch Sie Ihren Beitrag für eine bessere Zukunft aller Panketaler und Barnimer.
      </p>
</div>
<div>

<h3 class="text-xl pb-4 font-bold">Wem kommen die bis zu 5000€ eigentlich zugute?</h3>

<h4 class="pb-1 font-bold">Wir sind mitglieder in folgender Stiftungen</h4>
<ul class="list-disc list-inside pb-4">
  <li>Erster Bernauer Braugenossenschaft e.G</li>
  <li>BürgerGut Börnicke e.G </li>
  <li>Bürgerstiftung Panketal </li>
</ul>

<h4 class="pb-1 font-bold">Folgende Institutionen planen wir finanziell zu unterstützen </h4>
<ul class="list-disc list-inside">
 <li>Hospiz am Drachenkopf Eberswalde</li>
<li>Sonnenhof-Hospiz für Kinder und Jugendliche </li>
<li>Fußballförderverein Zepernick e.V.</li>
<li>Förderverein Kinder in Panketal e.V.</li>
<li>Förderverein der Freiwilligen Feuerwehr Zepernick e.V.</li>
<li>Förderverein Grundschule Zepernick</li>
<li>Förderverein der Gesamtschule Wilhelm-Conrad-Röntgen Zepernick e.V</li>
<li>Robert-Koch-Park Panketal e.V.</li>
<li>Kunst Brücke Panketal</li>
<li>Für Frauen e.V. (Frauenhaus Eberswalde)</li>
<li>BRH Rettungshundestaffel Barnim e.V. (Zepernick)</li>
<li>SG Empor Niederbarnim (Panketal)</li>
<li>Heimathaus e.V. Geschichtsverein Panketal</li>
</ul>

</div>
      </div>
     
      
   

    
    </section></div>
  </template>
  
  <script>
  // @ is an alias to /src

  export default {
    name: 'HistoryPage',
    components: {
     
    
    },
  }
  </script>